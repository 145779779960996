import { createStore } from '../createStore';

type Entry = {
  code: string;
  name: string;
  quantity: number;
  services: string[];
};

type State = {
  entries: Entry[];

  addEntry: (entry: Entry) => void;
  removeEntry: (code: string) => void;
  clearEntries: () => void;
};

export const useRemovedEntriesStore = createStore<State>((set, get) => ({
  entries: [],

  addEntry: (entry) => {
    set({ entries: [...get().entries, entry] });
  },
  removeEntry: (code) => {
    set({ entries: get().entries.filter((entry) => entry.code !== code) });
  },
  clearEntries: () => {
    set({ entries: [] });
  },
}));

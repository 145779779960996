type Config = {
  site: 'hub' | 'self-checkout' | 'jernia-no';
  apiBaseUrl: string;
  cdnUrl?: string;
  hasBasicAuth: boolean;
  imageUrlPrefix: string;
  solrApiPrefix: string;
  url: string;
  isHub: boolean;
  wpUrl?: string;
  b2bRegisterPath?: string;
  algoliaHostProtocol?: 'http' | 'https';
  vercelUrl?: string;
};

function configure(): Config {
  const jerniaEnv = process.env.NEXT_PUBLIC_JERNIA_ENV;
  const isDev = process.env.NODE_ENV === 'development';

  switch (jerniaEnv) {
    case 'jernia.no':
      return {
        site: 'jernia-no',
        apiBaseUrl: 'https://ecommerce.jernia.no/rest/v2/cnSite',
        cdnUrl: 'https://cdn.jernia.no',
        hasBasicAuth: false,
        imageUrlPrefix: 'https://ecommerce.jernia.no',
        isHub: false,
        solrApiPrefix: '/solr/api/product',
        url: 'https://www.jernia.no',
        wpUrl: 'https://content.jernia.no',
        b2bRegisterPath: '/login/b2b/register',
        algoliaHostProtocol: 'https',
        vercelUrl: 'https://jernia-no.vercel.app',
      };
    case 'jernia.no-test':
      return {
        site: 'jernia-no',
        apiBaseUrl: 'https://test-ecommerce.jernia.no/rest/v2/cnSite',
        cdnUrl: 'https://testcdn.jernia.no',
        hasBasicAuth: true,
        imageUrlPrefix: 'https://test-ecommerce.jernia.no',
        isHub: false,
        solrApiPrefix: '/solr/api/product',
        url: isDev ? 'http://localhost:3000' : 'https://beta-test.jernia.no',
        wpUrl: 'https://testcontent.jernia.no',
        b2bRegisterPath: '/login/register/b2b',
        algoliaHostProtocol: isDev ? 'http' : 'https',
        vercelUrl: 'https://beta-test.jernia.no',
      };
    case 'jernia.no-localhost':
      return {
        site: 'jernia-no',
        apiBaseUrl: 'http://jernia.local.no:9001/rest/v2/cnSite',
        hasBasicAuth: false,
        imageUrlPrefix: 'https://test.jernia.no',
        isHub: false,
        solrApiPrefix: '/solr/api/product',
        url: 'http://jernia.local.no:9001',
        wpUrl: 'https://testcontent.jernia.no',
      };
    case 'jernia.no-local':
      return {
        site: 'jernia-no',
        apiBaseUrl: 'https://test.jernia.no/rest/v2/cnSite',
        hasBasicAuth: false,
        imageUrlPrefix: 'https://www.jernia.no',
        isHub: false,
        solrApiPrefix: '/solr/api/product',
        url: 'http://localhost:3000',
        wpUrl: 'https://testcontent.jernia.no',
      };

    case 'self-checkout-test':
      return {
        site: 'self-checkout',
        apiBaseUrl: 'https://test.jernia.no/rest/v2/cnSite',
        hasBasicAuth: true,
        imageUrlPrefix: '/api/imageproxy',
        isHub: true,
        solrApiPrefix: '/api/search',
        url: 'https://test.jernia.no',
      };

    case 'self-checkout-prod':
      return {
        site: 'self-checkout',
        apiBaseUrl: 'https://www.jernia.no/rest/v2/cnSite',
        hasBasicAuth: false,
        imageUrlPrefix: 'https://www.jernia.no',
        isHub: true,
        solrApiPrefix: '/api/search',
        url: 'https://www.jernia.no',
      };

    case 'test':
    default:
      return {
        site: 'hub',
        apiBaseUrl: 'https://test.jernia.no/rest/v2/cnSite',
        hasBasicAuth: true,
        imageUrlPrefix: !isDev ? '/api/imageproxy' : '',
        isHub: true,
        solrApiPrefix: isDev ? '/api/search' : '/solr/api/product',
        url: 'https://test.jernia.no',
      };

    case 'production':
      return {
        site: 'hub',
        apiBaseUrl: 'https://ecommerce.jernia.no/rest/v2/cnSite',
        hasBasicAuth: false,
        imageUrlPrefix: 'https://ecommerce.jernia.no',
        isHub: true,
        solrApiPrefix: isDev ? '/api/search' : '/solr/api/product',
        url: 'https://ecommerce.jernia.no',
      };
  }
}

export const config = configure();
